.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* My code below */

.modal-backdrop {
  /* Cover the entire viewport */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Ensure it's above other content */

  /* Blur effect */
  backdrop-filter: blur(5px);
  /* background-color: rgba(0, 0, 0, 0.4);  */
  background-color: white;

  /* Flexbox to center the modal */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent; /* or whatever your modal's bg color is */
  z-index: 1001; /* Ensure it's above the backdrop */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}

.alert {
  background-color: #d4edda; /* Light green */
  color: #155724; /* Dark green */
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 10px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.text-input {
  width: 90%; /* Adjust as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
}

.start-btn {
  width: 100%;
  background-color: #007bff; /* Blue */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
}

.start-btn:hover {
  background-color: #0056b3; /* Darker blue */
}
